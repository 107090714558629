import {Component} from "@angular/core";

@Component({
    selector: "app-root",
    templateUrl: "app-root.html",
    styleUrls: ["app-root.scss"]
})
export class AppRoot {
    title = "kancelaria-faktury";
}

import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoot} from "./app-root";
import {GlobalModule} from "./global-module";
import {RoutingModule} from "./routing-module";

@NgModule({
    declarations: [
        AppRoot
    ],
    imports: [
        BrowserModule,
        GlobalModule,
        RoutingModule
    ],
    providers: [],
    bootstrap: [AppRoot]
})
export class AppModule {}

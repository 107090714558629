import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [
        RouterModule,
        CommonModule
    ],
    providers: []
})
export class GlobalModule {
}

import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {path: "", loadChildren: "./pages/home#HomeModule"}
        ], {scrollPositionRestoration: "enabled", urlUpdateStrategy: "eager"})
    ],
    exports: [RouterModule]
})
export class RoutingModule {}
